import React from 'react';
import BaseLayout from "../../Shared/layouts/baseLayout";
import CalculatorLayoutBase from "./sections/calculatorLayoutBase";
import SatisfactionSection from "./sections/satisfactionSection";
import WhatIsWinahost from "./sections/whatIsWinahost";
import WinahostAdvantages from "./sections/winahostAdvantages";
import WinahostMap from "./sections/winahostMap";
import Histories from "./sections/histories";
import WeWorkWithTheBest from "./sections/weWorkWithTheBest";
import HeaderMainSection from "./sections/headerMainSection";
import ReviewClientsTrustBox from "./sections/reviewClientsTrustBox";
import NewsReviewComponent from "./sections/components/newsReviewComponent";
import SponsorsLogos from "../../components/sponsors/sponsorsLogos";


const Main = () => {

    return (
        <BaseLayout>
            <HeaderMainSection />
            <CalculatorLayoutBase />
            <WeWorkWithTheBest />
            <SponsorsLogos customBackgroundColor={'var(--main-winahost-gray-color)'} logosColor={'winahost'}/>
            <WhatIsWinahost />
            <SatisfactionSection />
            <WinahostAdvantages />
            <WinahostMap />
            <NewsReviewComponent/>
            <ReviewClientsTrustBox/>
            <Histories />
        </BaseLayout>
    );
}

export default Main;
