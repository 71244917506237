export const metaLeadTrack = (): void => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        // @ts-ignore
        fbq('track', 'Lead');
    }
}


export function gtagLandingMainPageConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_MAIN_PAGE);
}

export function gtagLandingPricePageConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_PRICE_PAGE);
}

export function gtagLandingPageConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_CONTACTAR);
}

export function gtagLandingContactPageConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_CONTACTO);
}

export function gtagLandingContactNowConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_CONTACT_NOW);
}

export function gtagLandingQuickContactConversion() {
    gtagLandingConversion(process.env.REACT_APP_LANDING_QUICK_CONTACT);
}

const gtagLandingConversion = (id: string | null = null) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' && id) {
        // @ts-ignore
        if (typeof gtag === 'function') {
            // @ts-ignore
            gtag('event', 'conversion', {
                'send_to': id,
            });
        }
    }
}

