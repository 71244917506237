

export const validationEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !regex.test(email);
}

export const validationPhone = (phoneNumber: string): boolean => {
    const regex = /^(?:(?:\+|00)34)?[0-9]\d{8}$/;
    return !regex.test(phoneNumber);
}

export const validationTextNotEmpty = (text: string): boolean => {
    return !(text.trim().length > 0);
}

export const validationPassword = (password: string): boolean => {

    if (!textHasAtLeastEightCharacters(password)) {
        return false;
    }

    if (!textHasAtLeastACapitalLetter(password)) {
        return false;
    }

    if (!textHasAtLeastALowerCaseLetter(password)) {
        return false;
    }

    if (!textHasAtLeastANumber(password)) {
        return false;
    }

    if (!textHasAtLeastASpecialCharacter(password)) {
        return false;
    }

    return true;
}

export const textHasAtLeastEightCharacters = (text: string): boolean => {
    return text.length >= 8;
}

export const textHasAtLeastACapitalLetter = (text: string): boolean => {
    return /[A-Z]/.test(text);
}

export const textHasAtLeastALowerCaseLetter = (text: string): boolean => {
    return /[a-z]/.test(text);
}

export const textHasAtLeastANumber = (text: string): boolean => {
    return /[0-9]/.test(text);
}

export const textHasAtLeastASpecialCharacter = (text: string): boolean => {
    return /[!@#$%^&*()-_=+|{}?.,]/.test(text);
}

export const generateRandomPassword = () => {
    const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()-_=+|{}?.,';

    // Función para obtener un carácter aleatorio de una cadena
    function getRandomCharacter(chain: string) {
        return chain.charAt(Math.floor(Math.random() * chain.length));
    }

    // Generar una contraseña aleatoria de al menos 8 caracteres
    let password = '';
    password += getRandomCharacter(capitalLetters); // Al menos una mayúscula
    password += getRandomCharacter(lowerCaseLetters); // Al menos una minúscula
    password += getRandomCharacter(numbers); // Al menos un número
    password += getRandomCharacter(specialCharacters); // Al menos un carácter especial

    // Completar la contraseña con caracteres aleatorios
    const restOfCharacters = 8 - password.length;
    for (let i = 0; i < restOfCharacters; i++) {
        const allChars = capitalLetters + lowerCaseLetters + numbers + specialCharacters;
        password += getRandomCharacter(allChars);
    }

    // Mezclar la contraseña para que los caracteres aleatorios estén en diferentes posiciones
    password = password.split('').sort(function(){return 0.5-Math.random()}).join('');

    return password;
}
