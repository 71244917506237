import {Grid} from "@mui/material";
import * as React from "react";
import SocialNetworks from "./socialNetworks";
import {WHITE_TRANSPARENT} from "../../Shared/Constants/imageConstants";


export const ContactSocialNetworkDetails = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} textAlign={'center'}>
                        <img src={WHITE_TRANSPARENT}
                             alt="winahost-logo"
                             width="60%"
                        />
                        <SocialNetworks/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
