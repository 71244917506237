import * as React from 'react';
import Grid from '@mui/material/Grid';

import {Typography} from "@mui/material";
import {ContactSocialNetworkDetails} from "./contactSocialNetworkDetails";
import QuickLinks from "./quickLinks";
import {ContactLinkDetails} from "./contactLinkDetails";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {t} from "i18next";
import {EUROPEAN_SPONSORS} from "../../Shared/Constants/imageConstants";


const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
            <Grid container
                  sx={
                      {
                          flexGrow: 1,
                          backgroundColor: 'var(--main-winahost-color)',
                          padding: '50px 0 70px 0'
                      }
                  }
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
            >
                <Grid item xs={12} md={10} justifyContent={'center'}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} md={4}>
                            <ContactSocialNetworkDetails/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <QuickLinks/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ContactLinkDetails/>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} style={{backgroundColor: 'var(--main-winahost-color)'}}>
                <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
                    <Grid container justifyContent={'center'} style={{backgroundColor: 'var(--main-winahost-color)'}}>
                        <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
                            <img src={EUROPEAN_SPONSORS} alt="european_sponsors" className={'footerEuropeanSponsorsImage'}/>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} pt={1} pb={1} style={{backgroundColor: 'black'}}>
                <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
                    <Typography variant={'body1'} fontSize={'1em'} color={'white'}

                                dangerouslySetInnerHTML={createMarkup(`Copyright &copy; winahost.com ${year} ` + t('web.footer.allRights'))}/>
                </Grid>
            </Grid>
        </>
    );
}

export default Footer;
