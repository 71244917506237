import {Grid, Link} from "@mui/material";
import * as React from "react";
import {FACEBOOK_ICON, INSTAGRAM_ICON, LINKEDIN_ICON} from "../../Shared/Constants/logosConstants";

const socialNetworks = [
    {
        image: FACEBOOK_ICON,
        alt: "facebook_icon",
        path: "https://www.facebook.com/winahost"
    },
    {
        image: INSTAGRAM_ICON,
        alt: "instagram_icon",
        path: "https://www.instagram.com/winahost"
    },
    {
        image: LINKEDIN_ICON,
        alt: "linkedin_icon",
        path: "https://www.linkedin.com/company/winahost"
    }
];

const SocialNetworks = () => {
    return (
        <Grid container mt={1} spacing={2} justifyContent={'center'}>
            {socialNetworks.map((socialNetwork, index) => {
                return (
                    <Grid key={index} item xs={4} sm={2} md={2} lg={1.5}>
                        <Link
                            underline="none"
                            href={socialNetwork.path}
                            style={{color: 'white', textAlign: 'center'}}
                            target={'__blank'}
                        >
                            <img src={socialNetwork.image}
                                 alt={socialNetwork.alt}
                                 className={'socialNetworkIcons'}
                            />
                        </Link>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default SocialNetworks;
