import {Grid, Hidden, Link, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {ES_CONTACT} from "../../../routes/constantsRoutes";
import {SECOND_GIRL_DRAW} from "../../../Shared/Constants/imageConstants";



const BeginSection = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.beginSection'});

    const contactPath = ES_CONTACT;

    return (
        <Grid container justifyContent={'center'}>
            <Grid item style={styles.gridContainer} xs={12}>
                <Grid container direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      mt={5}
                      mb={5}
                >
                    <Grid item xs={10} sm={8} md={4}>
                        <Typography variant="h3" component="h3" fontSize={'1.7em'} mb={4} fontWeight={700} color={'black'}
                                    dangerouslySetInnerHTML={createMarkup(t('beginSectionTitle'))}>
                        </Typography>
                        <Typography variant={'body1'} mb={5} color={'black'}
                                    dangerouslySetInnerHTML={createMarkup(t('beginSectionBody'))}>
                        </Typography>
                        <Link rel={'canonical'}  key={'main_page'} href={`${contactPath}`}
                              underline="none"
                              className={'custom-winahost-button'}
                        >
                            <span style={{fontWeight: 'bold'}}>
                                {t('beginSectionButton')}
                            </span>
                        </Link>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item md={2}>
                            <img src={SECOND_GIRL_DRAW}
                                 alt="secondgirl"
                                 width={'90%'}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BeginSection;

const styles = {
    gridContainer: {
        backgroundColor: 'var(--main-winahost-gray-color)',
    }
};
