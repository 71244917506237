import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {Checkbox, Divider, FormControlLabel, Grid, Typography} from "@mui/material";
import * as React from "react";
import CustomDialog from "../../../components/Dialog/customDialog";
import PrivateText from "../../../Shared/sharedTexts/privateText";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {validationContactFormPage} from "../contact";

interface props {
    contact: LeadInterface,
    validateForm: boolean,
    setValidateForm: Function,
    validationFormPage: validationContactFormPage,
    setValidationFormPage: Function
}

const ReviseForm = ({contact, validateForm, setValidateForm, validationFormPage, setValidationFormPage}: props) => {

    const [openDialog, setOpenDialog] = React.useState(false);


    const checkedUsePoliticalAcceptance = () => {
        setValidateForm(!validateForm);
    }

    const openPoliticalPrivateDialog = () => {
        if (validateForm) {
            setValidateForm(!validateForm);
        } else {
            setOpenDialog(true);
        }
        setValidationFormPage({...validationFormPage, revise: !validateForm})
    }

    const _getAvailabilityText = (availability: string | null): string => {
        if (!availability) return '';

        switch (availability) {
            case 'fulltime':
                return 'Tiempo completo (9-12 meses por año)';
            case 'halftime':
                return 'Tiempo parcial (menos de 9 meses)';
            default:
                return '';
        }
    }

    const _getExtraText = (extras: string[] | null): string => {

        if (!extras) return '';

        let extras_translated: string[] = [];
        extras.map((extra) => {
            switch (extra) {
                case 'pool':
                    extras_translated.push('Piscina');
                    break
                case 'parking':
                    extras_translated.push('Aparcamiento');
                    break
                default:
                    break;
            }
        })

        if (extras_translated.length === 0) return '---';
        return extras_translated.join(', ')
    }


    return (
        <Grid container justifyContent={'left'} mt={3} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'body1'} fontSize={'1.7em'} color={'black'}
                            fontWeight={400} mb={3}
                            dangerouslySetInnerHTML={createMarkup('¡Vamos a revisar la información!')}/>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} sm={6} p={3}>
                    <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                fontWeight={500} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Información de la propiedad')}/>
                    <Divider style={{backgroundColor: 'black', marginBottom: 30}}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Localización:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.address)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Habitaciones:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.bedrooms) ? contact.property.bedrooms.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Ocupantes:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.accommodates) ? contact.property.accommodates.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Baños:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup((contact.property.bathrooms) ? contact.property.bathrooms.toString() : '')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Disponibilidad:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(_getAvailabilityText(contact.property.availability))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('extras:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(_getExtraText(contact.property.extras))}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('¿Eres el dueño de esta propiedad?')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.owner === 'yes' ? 'Sí' : 'No')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('¿Estas pensando en contratar una empresa de gestión para alquilar tu vivienda?')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.hire === 'yes' ? 'Sí' : 'No')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('¿Tienes tramitada la licencia necesaria para alquilar en tu ciudad?')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.licence === 'yes' ? 'Sí' : 'No')}/>
                </Grid>

                <Grid item xs={12} sm={6} p={3}>
                    <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                fontWeight={500} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Información del propietaio')}/>
                    <Divider style={{backgroundColor: 'black', marginBottom: 30}}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Nombre:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.name)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Correo electrónico:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.email)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('Teléfono:')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.user.phone)}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                fontWeight={600} mb={3}
                                dangerouslySetInnerHTML={createMarkup('¿Quieres que un Agente Winahost te llame para darte información más detallada de nuestros servicios para ayudarte a rentabilizar tu vivienda?')}/>

                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={3}
                                dangerouslySetInnerHTML={createMarkup(contact.property.call === 'yes' ? 'Sí' : 'No')}/>

                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} p={3}>
                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={<Checkbox checked={validateForm} onClick={openPoliticalPrivateDialog}/>}
                        label="Aceptar políticas de uso y privacidad"/>
                </Grid>
            </Grid>
            <CustomDialog title={'Política de Privacidad'} content={<PrivateText/>} open={openDialog}
                          setOpen={setOpenDialog} customFunction={checkedUsePoliticalAcceptance}/>
        </Grid>
    );
}

export default ReviseForm;
