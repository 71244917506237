
import Box from "@mui/material/Box";
import {Button, Drawer, Link, List, ListItem} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import {PathRouteInterface} from "../navbar/NavBarPages";
import {WHITE_TRANSPARENT} from "../../Shared/Constants/imageConstants";


interface props {
    openDrawer: boolean,
    closeDrawer: Function,
    pages: Array<PathRouteInterface>,
    lang: string
}

const SideMenuDrawer = ({openDrawer, closeDrawer, pages, lang}: props) => {

    return (
        <Drawer
            anchor={'left'}
            open={openDrawer}
        >
            <Box
                role="presentation"
                onClick={() => {
                }}
                onKeyDown={() => closeDrawer()}
                className={`drawerBoxPresentation`}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <List>
                            <ListItem key={'main_page'}>
                                <Link rel={'canonical'}  href={'/'}>
                                    <img src={WHITE_TRANSPARENT}
                                         alt={"logo winahost"}
                                         style={{width: '190px'}}
                                    />
                                </Link>
                            </ListItem>
                            {pages.map(page => {
                                    return (
                                        <ListItem key={page.id} style={{margin: 15}}>
                                            <Link rel={'canonical'}  href={page[`path_${lang}` as keyof typeof page]} target={page.target}
                                                  style={{color: 'white'}}>{page[`name_${lang}` as keyof typeof page]}</Link>
                                        </ListItem>
                                    )
                                }
                            )}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => closeDrawer()} className={`drawerContainerButton`}>
                        <span
                            style={{
                                fontSize: 16,
                                color: 'white',
                                textAlign: 'left',
                                margin: '10px 10px 0 0',
                                padding: '5px 5px 0 0'
                            }}
                        >
                            Close
                        </span>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
}

export default SideMenuDrawer;
