import {Box, Button, Grid, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {SyntheticEvent, useState} from "react";
import * as React from "react";

import './../../Styles/customStyles/customButtons.css';
import {LeadInterface} from "../../interfaces/leadInterface";
import {leadFormLandingBase} from "../../Shared/baseData/leadBaseData";
import LocationLandingForm from "./sections/form/LocationLandingForm";
import TypeHireLandingForm from "./sections/form/typeHireLandingForm";
import TouristicLicenceLandingForm from "./sections/form/touristicLicenceLandingForm";
import LeadContactLandingForm from "./sections/form/leadContactLandingForm";
import BaseWithoutNavBarLayout from "../../Shared/layouts/baseWithoutNavBarLayout";
import SponsorsLogos from "../../components/sponsors/sponsorsLogos";
import WinahostEasier from "../serviceSection/sections/winahostEasier";
import BeginContactSection from "./sections/beginContactSection";
import ManagementContactHeader from "./sections/managementContactHeader";

import './../../Styles/landignFormCss.css';
import CongratulationsLandingForm from "./sections/form/congratulationsLandingForm";
import {formatLeadMergeData} from "../../hooks/leadsHook";
import {upsertHubspotContact} from "../../Shared/hooks/upsertHubspotContact";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {gtagLandingPageConversion, metaLeadTrack} from "../../hooks/socialMeditionTags";

export interface validationFormPage {
    location: boolean,
    hire: boolean,
    licence: boolean,
    user: boolean
}

const LandingForm = () => {

    const [lead, setLead] = useState<LeadInterface>(leadFormLandingBase);

    const [index, setIndex] = useState<string>("1");
    const [validationPage, setValidationPage] = useState<number>(1);

    const [validationFormPage, setValidationFormPage] = useState<validationFormPage>(
        {
            location: false,
            hire: false,
            licence: false,
            user: false
        }
    );

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setIndex(newValue);
    };

    const handleSendLeadToHubspot = () => {

        metaLeadTrack();
        gtagLandingPageConversion();

        const leadMergeData = formatLeadMergeData(lead);
        upsertHubspotContact(leadMergeData);
    }

    const handleChangePageAdd = () => {
        setIndex((parseInt(index) + 1).toString());
        setValidationPage(validationPage + 1);
    }

    return (
        <BaseWithoutNavBarLayout>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} className={'backgroundFormLanding'}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} mt={5} textAlign={'center'}>
                            <Typography variant={'body1'} fontWeight={700} mt={3} className={'managementHeaderTitle'} color={'white'}
                                        dangerouslySetInnerHTML={createMarkup('Alquila tu vivienda rápido y gana en rentabilidad')}/>

                            <Typography variant={'body1'} fontWeight={600} mt={3} fontSize={'.9em'} color={'white'}
                                        dangerouslySetInnerHTML={createMarkup('En una semana tu propiedad disponible para recibir reservas desde las plataformas conectadas')}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={9} md={8} lg={6} xl={7}>
                            <TabContext value={index.toString()}>

                                <Box sx={{borderBottom: 1, borderColor: 'divider'}} >
                                    <TabList onChange={handleChange} variant="fullWidth">
                                        <Tab label="" value={"1"}/>
                                        <Tab label="" value={"2"} disabled={(validationPage < 2)}/>
                                        <Tab label="" value={"3"} disabled={(validationPage < 3)}/>
                                        <Tab label="" value={"4"} disabled={(validationPage < 4)}/>
                                        <Tab label="" value={"5"} disabled={(validationPage < 5)}/>
                                    </TabList>
                                </Box>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} xl={8} style={{
                                        backgroundColor: '#fff',
                                        marginTop: '10px',
                                        borderRadius: '10px'
                                    }}>
                                        <TabPanel value={"1"}>
                                            <BaseLandingLayout
                                                index={index}
                                                setIndex={setIndex}
                                                validationPage={validationPage}
                                                setValidationPage={setValidationPage}
                                                validationFormPage={validationFormPage}
                                                customFunction={null}
                                            >
                                                <LocationLandingForm
                                                    lead={lead}
                                                    setLead={setLead}
                                                    validationFormPage={validationFormPage}
                                                    setValidationFormPage={setValidationFormPage}
                                                />
                                            </BaseLandingLayout>
                                        </TabPanel>
                                        <TabPanel value={"2"}>
                                            <BaseLandingLayout
                                                index={index}
                                                setIndex={setIndex}
                                                validationPage={validationPage}
                                                setValidationPage={setValidationPage}
                                                validationFormPage={validationFormPage}
                                                customFunction={null}
                                            >
                                                <TypeHireLandingForm
                                                    lead={lead}
                                                    setLead={setLead}
                                                    validationFormPage={validationFormPage}
                                                    setValidationFormPage={setValidationFormPage}
                                                    handleChangePageAdd={handleChangePageAdd}
                                                />
                                            </BaseLandingLayout>
                                        </TabPanel>
                                        <TabPanel value={"3"}>
                                            <BaseLandingLayout
                                                index={index}
                                                setIndex={setIndex}
                                                validationPage={validationPage}
                                                setValidationPage={setValidationPage}
                                                validationFormPage={validationFormPage}
                                                customFunction={null}
                                            >
                                                <TouristicLicenceLandingForm
                                                    lead={lead}
                                                    setLead={setLead}
                                                    validationFormPage={validationFormPage}
                                                    setValidationFormPage={setValidationFormPage}
                                                    handleChangePageAdd={handleChangePageAdd}
                                                />
                                            </BaseLandingLayout>
                                        </TabPanel>
                                        <TabPanel value={"4"}>
                                            <BaseLandingLayout
                                                index={index}
                                                setIndex={setIndex}
                                                validationPage={validationPage}
                                                setValidationPage={setValidationPage}
                                                validationFormPage={validationFormPage}
                                                customFunction={handleSendLeadToHubspot}
                                            >
                                                <LeadContactLandingForm
                                                    lead={lead}
                                                    setLead={setLead}
                                                    validationFormPage={validationFormPage}
                                                    setValidationFormPage={setValidationFormPage}
                                                />
                                            </BaseLandingLayout>
                                        </TabPanel>
                                        <TabPanel value={"5"}>
                                            <BaseLandingLayout
                                                index={index}
                                                setIndex={setIndex}
                                                validationPage={validationPage}
                                                setValidationPage={setValidationPage}
                                                validationFormPage={validationFormPage}
                                                customFunction={null}
                                            >
                                                <CongratulationsLandingForm/>
                                            </BaseLandingLayout>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </TabContext>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ManagementContactHeader/>
            <SponsorsLogos logosColor={'winahost'}/>
            <WinahostEasier/>
            <BeginContactSection/>
        </BaseWithoutNavBarLayout>
    );

}


interface props {
    children: JSX.Element | JSX.Element[],
    index: string,
    setIndex: Function,
    validationPage: number,
    setValidationPage: Function,
    validationFormPage: validationFormPage,
    customFunction: Function | null
}


const BaseLandingLayout = (
    {
        children,
        index,
        setIndex,
        validationPage,
        setValidationPage,
        validationFormPage,
        customFunction
    }: props) => {

    const handleChangePageAdd = () => {
        setIndex((parseInt(index) + 1).toString());
        setValidationPage(validationPage + 1);
    }

    const handleFinishForm = () => {
        handleChangePageAdd();

        if (customFunction) {
            customFunction();
        }
    }

    const checkValidationNextButton = () => {
        if (index === '1' && !validationFormPage.location) return true;
        if (index === '2' && !validationFormPage.hire) return true;
        if (index === '3' && !validationFormPage.licence) return true;
        if (index === '4' && !validationFormPage.user) return true;
        return false;
    }

    if (index === '5') {
        return (
            <Grid container justifyContent={'center'} mt={2}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        );
    }

    let textButton = 'Siguiente';
    if (parseInt(index) === 1) {
        textButton = 'Infórmate gratis';
    }
    if (parseInt(index) === 4) {
        textButton = 'Finalizar';
    }

    let displayNextButton = 'block';
    if (parseInt(index) === 2 || parseInt(index) === 3) {
        displayNextButton = 'none';
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                {children}
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} mt={4}>
                    <Grid item xs={12}>
                        <Button
                            disabled={checkValidationNextButton()}
                            variant={'outlined'}
                            className={'custom-winahost-button'}
                            style={{
                                width: '100%',
                                borderRadius: 50,
                                display: displayNextButton
                            }}
                            onClick={() => (parseInt(index) === 4) ? handleFinishForm() : handleChangePageAdd()}
                        >
                            {textButton}
                        </Button>
                        <Typography variant={'body1'} fontWeight={400} mb={3} mt={3} fontSize={'.9em'}
                                    dangerouslySetInnerHTML={createMarkup('O si prefieres, llámanos al 930 500 225')}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}


/**
 * 21
 */


/**
 * 95
 */


/**
 * mgf
 */
export default LandingForm;
