import {Grid, Hidden, Typography} from "@mui/material";
import QuickContactStepper from "./quickContactStepper";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useState} from "react";
import CongratulationsContactquickContact from "./congratulationsContactquickContact";
import {LANDING_B, WINAHOST_TRANSPARENT_MODIFIED} from "../../../../Shared/Constants/imageConstants";
import CustomDialog from "../../../../components/Dialog/customDialog";
import PrivateText from "../../../../Shared/sharedTexts/privateText";


const QuickContactContent = () => {

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState(0);

    const getFormStepper = () => {
        switch (activeStep) {
            case 0:
            case 1:
                return formView();
            case 2:
                return <CongratulationsContactquickContact/>;
            default:
                return 'not view available';
        }
    }

    const formView = () => {
        return (
            <Grid item xs={11} sm={11} md={11} lg={8} xl={6}>
                <QuickContactStepper activeStep={activeStep} setActiveStep={setActiveStep}/>
                <Grid item xs={12} mt={3}>
                    <Typography variant={'body1'} mb={5} color={'gray'} fontSize={'.8em'} onClick={() => {
                        setOpenDialog(true)
                    }} style={{cursor: 'pointer'}}
                                dangerouslySetInnerHTML={createMarkup('Al enviarlo, acepto que la <strong>Política de privacidad, cookies y Política de tratamiento de datos de Winahost</strong> se apliquen a las comunicaciones de Winahost')}>
                    </Typography>
                </Grid>
                <CustomDialog title={'Política de Privacidad'} content={<PrivateText/>} open={openDialog}
                              setOpen={setOpenDialog} customFunction={() => {
                }}/>
            </Grid>
        );
    }


    return (
        <Grid container justifyContent={'center'} style={{background: '#f8f8f8', height: '100%'}}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'} style={{height: '100vh', display: "flex"}}>
                    <Grid item xs={12} sm={12} md={7} lg={8} xl={8} mt={3} style={{margin: 'auto auto'}}>
                        <Hidden lgDown>
                            <img src={WINAHOST_TRANSPARENT_MODIFIED} alt="logo" width={'150px'}
                                 style={{position: 'absolute', top: 0, margin: '20px 20px'}}/>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={12} textAlign={'center'}>
                                <img src={WINAHOST_TRANSPARENT_MODIFIED} alt="logo" width={'150px'}
                                     style={{margin: '20px auto 50px auto', textAlign: 'center'}}/>
                            </Grid>
                        </Hidden>
                        <Grid container justifyContent={'center'}>
                            {getFormStepper()}
                        </Grid>
                    </Grid>
                    <Hidden lgDown>
                        <Grid item md={5} lg={4} xl={4}
                              style={{background: '#038080', height: '100%', alignItems: 'center', margin: 'auto 0'}}>
                            <Grid container direction="row" justifyContent="center" alignItems="center"
                                  style={{height: '100%'}}>
                                <Grid item xs={11} textAlign={'center'}>
                                        <Typography variant={'body1'} mb={1} color={'white'} fontSize={'2em'}
                                                    fontWeight={600}
                                                    dangerouslySetInnerHTML={createMarkup('Descubre ahora la rentabilidad que te ofrece Winahost')}>
                                        </Typography>

                                        <Typography variant={'body1'} mb={1} color={'white'} fontSize={'1.3em'}
                                                    dangerouslySetInnerHTML={createMarkup('Miles de propietarios y huéspedes ya trabajan con nosotros')}>
                                        </Typography>

                                    <img src={LANDING_B} alt="LANDING_B_IMAGE" width={'100%'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default QuickContactContent;
