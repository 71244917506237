import {Grid, Typography} from "@mui/material";
import {Banner} from "../../../components/banner/banner";
import React from "react";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


const SatisfactionSection = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.satisfactionSection'});

    return (
        <Grid container justifyContent={'center'} mt={5} style={{backgroundColor: 'var(--main-winahost-gray-color)'}}>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={9}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography variant="h5" style={{padding: '30px 0 30px 0'}} fontWeight={700}
                                    dangerouslySetInnerHTML={createMarkup(t('expertsToTitle'))}/>
                        <Banner objects={[
                            '' + t('propertyAttention'),
                            '' + t('guestAttention'),
                            '' + t('cleaning'),
                            '' + t('checkInOut'),
                            '' + t('reservation'),
                            '' + t('platform'),
                            '' + t('optimizedPrices'),
                        ]}
                                type={'text'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SatisfactionSection;
