import {LeadInterface} from "../../interfaces/leadInterface";


export const calculateRentalizerByExtras = (lead: LeadInterface, rentalizer: any) => {

    if (!lead.property.extras) return rentalizer;

    let incrementPercentage = 0;
    if (lead.property.extras.includes('terrace')) {
        incrementPercentage += 5;
    }
    if (lead.property.extras.includes('parking')) {
        incrementPercentage += 5;
    }
    if (lead.property.extras.includes('pool')) {
        incrementPercentage += 15;
    }

    const percentage = incrementPercentage / 100;

    rentalizer.property_stats.revenue.ltm = rentalizer.property_stats.revenue.ltm + (rentalizer.property_stats.revenue.ltm * percentage);
    rentalizer.property_stats.adr.ltm = rentalizer.property_stats.adr.ltm + (rentalizer.property_stats.adr.ltm * percentage);

    return rentalizer;
}
