import React from "react";
import {AppBar, Box, Link} from "@mui/material";
import {WINAHOST_TRANSPARENT} from "../../Shared/Constants/imageConstants";


interface props {
    lang: string
}

function NavBarWithoutButtons({lang}: props) {


    const mainRoute: string = (lang === 'es') ? '/' : `/${lang}/`;

    return (
        <AppBar position="fixed" style={{backgroundColor: 'white', boxShadow: 'none', padding: '5px 0'}}>
            <Box sx={{mr: 1, mx: 2, textAlign: 'center'}}>
                <Link rel={'canonical'} key={'main_page'} href={mainRoute}>
                    <img src={WINAHOST_TRANSPARENT}
                         alt={"logo winahost"}
                         style={{width: '190px'}}
                    />
                </Link>
            </Box>
        </AppBar>
    );
}

export default NavBarWithoutButtons;
