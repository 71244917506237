import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../utils/textUtilsResources";

const LegalTextContent = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} mt={5} mb={5}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h1'} fontSize={'2em'} fontWeight={'bold'} dangerouslySetInnerHTML={createMarkup('Política de Privacidad')}/>

                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>I. Introducción</strong><br/>' +
                                        '                    Es política de Winahost respetar su privacidad con respecto a cualquier información recopilada durante el uso de nuestro sitio web. Esta Política de privacidad se aplica al sitio web www.winahost.com/ (en lo sucesivo, «nosotros» o «www.winahost.com/»). Respetamos su privacidad y nos comprometemos a proteger la información de identificación personal que pueda proporcionarnos a través del sitio web. Hemos adoptado esta política de privacidad («Política de privacidad») para explicar qué tipo de información se recopila en nuestro sitio web, cómo se utiliza esta información y en qué circunstancias podemos divulgar la información a terceros. Esta Política de privacidad se aplica solo a la información recopilada a través del sitio web y no se aplica a nuestra recopilación de información de otras fuentes.<br/>' +
                                        '                    Esta Política de privacidad, junto con los Términos y condiciones publicados en nuestro sitio web, dictan las reglas y políticas generales que cubren su uso de nuestro sitio web. Dependiendo de su actividad mientras visita nuestro sitio web, es posible que deba aceptar términos y condiciones adicionales.')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>II. Visitantes del sitio web</strong><br/>' +
                                        '                    Como la mayoría de los operadores de sitios web, Winahost recopila información de identificación no personal que los navegadores y servidores web suelen poner a disposición, como el tipo de motor del navegador, la preferencia de idioma, el sitio web de referencia y la fecha y hora del pedido de cada visitante. Winahost recopila información de identificación no personal para comprender cómo los visitantes usan su sitio web. De vez en cuando, Winahost puede divulgar información de identificación no personal en forma agregada (por ejemplo, mediante la publicación de informes de tendencias en el uso de su sitio web).<br/>' +
                                        '                    Winahost también recopila información potencialmente identificable como la dirección de Protocolo de Internet (IP) de los usuarios que están conectados. Winahost solo revela las direcciones IP de los usuarios que iniciaron sesión en las mismas circunstancias en las que usa y revela información de identificación personal como se describe a continuación.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>III. Recopilación de información de identificación personal</strong><br/>' +
                                        '                    Algunos visitantes del sitio web de Winahost eligen interactuar con Winahost, lo que de alguna manera requiere que Winahost recopile información de identificación personal. La cantidad y el tipo de información recopilada por Winahost depende de la naturaleza de la interacción. Por ejemplo, pedimos a los usuarios que visitan https://www.winahost.com/ que nos proporcionen un nombre de usuario y una dirección de correo electrónico.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>IV. Seguridad</strong><br/>' +
                                        '                    La seguridad de su información personal es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger su información personal, no podemos garantizar su seguridad absoluta.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>V. Enlaces a sitios web externos</strong><br/>' +
                                        '                    Nuestro Servicio puede contener enlaces a sitios web que no son administrados por nosotros. Si hace clic en un enlace de un tercero, se lo dirigirá al sitio web de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad y los términos y condiciones de cada sitio web que visite.<br/>' +
                                        '                    No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web, productos o servicios de terceros.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>VI. Licencia</strong><br/>' +
                                        '                    A menos que se indique lo contrario, Winahost y / o sus licenciantes conservan los derechos de propiedad intelectual de todo el material en www.winahost.com y cualquier subdominio o subcarpeta de www.winahost.com. Todos los derechos de propiedad intelectual están reservados. Puede ver y / o imprimir páginas de www.winahost.com para uso personal sujeto a las restricciones establecidas en estos términos y condiciones.<br/>' +
                                        '                    No puede:<br/>' +
                                        '<ul>' +
                                        '<li>Vuelva a publicar material de www.winahost.com</li>' +
                                        '<li>Vender, alquilar o sublicenciar material de www.winahost.com</li>' +
                                        '<li>Reproducir, duplicar o copiar material de www.winahost.com</li>' +
                                        '<li>Redistribuir el contenido de Winahost (a menos que el contenido se haya creado específicamente para la redistribución)</li>' +
                                        '</ul>' +
                                        '')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>VII. Protección de cierta información de identificación personal</strong><br/>' +
                                        '                    Winahost divulga tanto la identificación personal como información de identificación personal potencialmente solo para sus empleados, contratistas y organizaciones afiliadas que (i) necesitan dicha información para procesarla en nombre de Winahost o para proporcionar servicios disponibles en el sitio web de Winahost, y (ii) que han acordado no divulgar esta información también mucho. Algunos de estos empleados, contratistas y organizaciones afiliadas pueden estar ubicados fuera de su país de origen; Al utilizar el sitio web de Winahost, usted da su consentimiento para que se les transfiera dicha información. Winahost no alquilará ni venderá información de identificación personal ni información de identificación personal a nadie. Aparte de sus empleados, contratistas y organizaciones afiliadas como se describe anteriormente, Winahost divulga información de identificación personal potencialmente e información de identificación personal solo por orden judicial, orden judicial u otras solicitudes gubernamentales, o cuando Winahost cree de buena fe que la divulgación es razonablemente necesaria para proteger los derechos de propiedad de Winahost, terceros o el público en general.<br/>' +
                                        '                    Si es un usuario registrado en https://www.winahost.com/ y ha proporcionado su dirección de correo electrónico, Winahost puede ocasionalmente enviarle un correo electrónico para informarle sobre novedades, pedir su opinión o simplemente para mantenerse al día. Se trata de Winahost y nuestros productos. Este tipo de información se comunica principalmente a través de nuestro blog, por lo que es de esperar que este tipo de contacto sea mínimo. Si nos envía una solicitud (por ejemplo, a través de soporte por correo electrónico o uno de nuestros mecanismos de respuesta), nos reservamos el derecho de publicarla para ayudarnos a aclarar o responder a la solicitud o para ayudar a otros usuarios. Winahost toma todas las medidas razonables para protegerse contra el acceso, uso, alteración o destrucción no autorizados de información potencialmente identificable e información de identificación personal.<br/>' +
                                        '                    Si no estamos presentes en su zona, sus datos podrán ser compartidos con un socio de confianza que también trabaje en el sector de la gestión inmobiliaria y que podrá ponerse en contacto con usted para ofrecerle sus servicios. Póngase en contacto con <a href="mailto:gdpr@Winahost.com">gdpr@Winahost.com</a> para obtener una lista completa de nuestros socios.<br/>' +
                                        '                    Si ha manifestado interés en vender su propiedad o en realizar una inversión mayor en alquileres a corto plazo, también podemos transmitir sus datos a nuestros socios afiliados especializados en este ámbito. Póngase en contacto con <a href="mailto:gdpr@Winahost.com">gdpr@Winahost.com</a> para obtener una lista completa de nuestros socios.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>VIII. Estadísticas agregadas</strong><br/>' +
                                        '                    Winahost puede recopilar estadísticas sobre el comportamiento de los visitantes del sitio web. Winahost puede mostrar públicamente esta información o proporcionarla a otros. Sin embargo, Winahost no revela su información de identificación personal.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>IX. Comercio electrónico</strong><br/>' +
                                        '                    Aquellos que participan en transacciones de Winahost: cuando compran servicios o productos de Winahost, se les solicita información adicional, incluida la información personal y financiera necesaria para procesar las transacciones. En cada caso, Winahost recopila dicha información solo en la medida necesaria o apropiada para cumplir con el propósito de la interacción del visitante con Winahost. Winahost no divulga información de identificación personal excepto en los casos descritos. Y los visitantes siempre pueden negarse a proporcionar información de identificación personal, con la salvedad de que puede evitar que interactúen con actividades relacionadas con el sitio web.<br/>' +
                                        '                    ')}/>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant={'body1'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>X. Cambios en la Política de privacidad</strong><br/>' +
                                        '                    La Política de privacidad de Winahost puede cambiar de vez en cuando, aunque es probable que sea mínima y exclusivamente a discreción de Winahost. Winahost anima a los visitantes a que consulten con frecuencia esta página en caso de que se produzcan modificaciones en su Política de privacidad. Su uso continuado de este sitio web después de cualquier modificación a la Política de privacidad se interpretará como la aceptación de dichas modificaciones.<br/>')}/>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default LegalTextContent;
