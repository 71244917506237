

export const MAIN_ROUTE = '/';
export const ENGLISH_MAIN_ROUTE = '/en';

export const ES_SERVICE = '/servicio';
export const ES_TECHNOLOGY = '/tecnologia';
export const ES_PRICES = '/tarifa';
export const ES_RESOURCES = '/recursos';
export const ES_BLOG = '/blog';
export const ES_US = '/conocenos';
export const ES_CONTACT = '/contacto';
export const ES_PROFESSIONAL = '/profesional';
export const ES_LEGAL_TEXT = '/aviso-legal';

export const EN_SERVICE = '/service';
export const EN_TECHNOLOGY = '/technology';
export const EN_PRICES = '/fee';
export const EN_RESOURCES = '/resources';
export const EN_BLOG = '/blog';
export const EN_US = '/meet-us';
export const EN_CONTACT = '/contact';
export const EN_PROFESSIONAL = '/professional';
export const EN_LEGAL_TEXT = '/legal-text';

export const ONLINE = '/online';
export const PREMIUM = '/premium';

export const LANDING_FORM = 'contactar';
export const LANDING_CONTACT_NOW = 'contacta-ahora';
export const LANDING_QUICK_CONTACT = 'contacto-rapido';

export const ASTERISK = '*';

export const LOGIN_OWNERS = '/propietarios/login';
export const RECOVERY_PASSWORD_OWNERS = '/propietarios/recuperar-password';
export const CHANGE_PASSWORD_OWNERS = '/owner/change-password';
