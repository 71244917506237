
/**
* IMAGES
* */

export const APP_HUESPEDES_V2 = 'https://winahost.s3.eu-west-1.amazonaws.com/images/APP_Huespedes_v2.jpeg';
export const BOMBILLA = 'https://winahost.s3.eu-west-1.amazonaws.com/images/bombilla.jpeg';
export const CALL_CENTER = 'https://winahost.s3.eu-west-1.amazonaws.com/images/call_center.png';
export const CALL_CENTER_WORKER = 'https://winahost.s3.eu-west-1.amazonaws.com/images/call_center_worker.png';
export const CALM_MAN = 'https://winahost.s3.eu-west-1.amazonaws.com/images/calm_man.png';
export const CONNECTING_WORLD = 'https://winahost.s3.eu-west-1.amazonaws.com/images/connecting_world.png';
export const DISENO_INTERIOR_CASA_CONTEMPORANEA = 'https://winahost.s3.eu-west-1.amazonaws.com/images/diseno-interior-casa-contemporanea.jpg';
export const EUROPEAN_SPONSORS = 'https://winahost.s3.eu-west-1.amazonaws.com/images/european_sponsors.jpeg';
export const FAMILY_TENT = 'https://winahost.s3.eu-west-1.amazonaws.com/images/family_tent.png';
export const FUNNY_GIRL = 'https://winahost.s3.eu-west-1.amazonaws.com/images/funny_girl.png';
export const GIRL_DRAW = 'https://winahost.s3.eu-west-1.amazonaws.com/images/girl_draw.png';
export const GIVE_KEYS = 'https://winahost.s3.eu-west-1.amazonaws.com/images/give_keys.png';
export const INTELLIGENCEARTIFICIAL = 'https://winahost.s3.eu-west-1.amazonaws.com/images/intelligenceArtificial.jpeg';
export const KEYS = 'https://winahost.s3.eu-west-1.amazonaws.com/images/keys.png';
export const LANDING_B = 'https://winahost.s3.eu-west-1.amazonaws.com/images/LANDING_B.png';
export const LAPTOP_MANAGEMENT = 'https://winahost.s3.eu-west-1.amazonaws.com/images/laptop_management.jpg';
export const LIVINGROOM_IMAGE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/livingroom_image.png';
export const MAP_TECHNOLOGYC = 'https://winahost.s3.eu-west-1.amazonaws.com/images/map_technologyc.png';
export const MOBILE_PLATFORM = 'https://winahost.s3.eu-west-1.amazonaws.com/images/mobile-platform.png';
export const PROVEEDORES = 'https://winahost.s3.eu-west-1.amazonaws.com/images/Proveedores.jpg';
export const SALA_ESTAR_LUJO_LOFT_RENDERIZADO_3D_ESTANTERIA_CERCA_ESTANTERIA = 'https://winahost.s3.eu-west-1.amazonaws.com/images/sala-estar-lujo-loft-renderizado-3d-estanteria-cerca-estanteria.jpg';
export const SECOND_GIRL_DRAW = 'https://winahost.s3.eu-west-1.amazonaws.com/images/second_girl_draw.png';
export const TAKING_A_PICTURE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/taking_a_picture.png';
export const TECHNOLOGYPHOTO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/technologyPhoto.jpeg';
export const TRABAJADORES_HABLAR_TELEFONO_HACER_PAPELEO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/trabajadores-hablar-telefono-hacer-papeleo.jpg';
export const WASHING_MACHINE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/washing_machine.png';
export const WASHING_MACHINE_SECTION = 'https://winahost.s3.eu-west-1.amazonaws.com/images/washing_machine_section.png';
export const WHITE_TRANSPARENT = 'https://winahost.s3.eu-west-1.amazonaws.com/images/white-transparent.png';
export const WINAHOST_GRAPHYCS = 'https://winahost.s3.eu-west-1.amazonaws.com/images/winahost_graphycs.png';
export const WINAHOST_TRANSPARENT = 'https://winahost.s3.eu-west-1.amazonaws.com/images/winahost_transparent.png';
export const WINAHOST_TRANSPARENT_MODIFIED = 'https://winahost.s3.eu-west-1.amazonaws.com/images/winahost_transparent-modified.png';
export const WOMAN_SENIOR_WORKING = 'https://winahost.s3.eu-west-1.amazonaws.com/images/woman_senior_working.jpeg';
export const WORK_SPACE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/work_space.png';
export const WORKER_GUY = 'https://winahost.s3.eu-west-1.amazonaws.com/images/worker_guy.png';
export const WORLD_BALL = 'https://winahost.s3.eu-west-1.amazonaws.com/images/world_ball.jpeg';

