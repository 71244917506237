import './banner.css'
import {Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";

interface props {
    objects: Object[],
    type: string
}

const Banner = ({objects, type}: props) => {

    const getObjectType = (object: Object, type: string) => {
        switch (type) {
            case 'text':
                return textType(objects);
            case 'image':
                return  pictureType(objects);
        }
    }

    return (
        <div className="inner">
            <div className="wrapper">
                {getObjectType(objects, type)}
                {getObjectType(objects, type)}
                {getObjectType(objects, type)}
            </div>
        </div>
    );
};

const textType = (objects: Object[]) => {
    return (
        <section className={'bannerTextSection'}>
            {objects.map((object, index) => {
                    return (
                        <Typography key={index} variant="body1" fontSize={'2.3rem'} mb={3} fontWeight={700}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup(object.toString())}/>
                    );
                }
            )}
        </section>
    )
}

const pictureType = (objects: Object[]) => {
    return (
        <section className={'bannerImageSection'}>
            {objects.map((object, index) => {

                const width = object['width'] ?? 'auto';
                const height = object['height'] ?? 'auto';

                    return (
                        <img key={index} className={"bannerImageImage"} src={object['path'].toString()} alt={object['alt']} style={{
                            width: width,
                            height: `${height}`
                        }} />
                    );
                }
            )}
        </section>
    )
}

export {Banner};
